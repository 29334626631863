<template>
  <div>
    <div class="title-container">
      <vh-tooltip effect="dark" :content="title" placement="bottom">
        <span class="cardTitle">{{ title | overHidden(7) }}</span>
      </vh-tooltip>
      <span>点击人数列表</span>
      <vh-tooltip effect="dark" content="" placement="right">
        <div slot="content">
          1.同一场次下人数去重，同一参会ID的用户只会出现1条记录
          <br />
          2.只有设置跳转链接后，才会收集点击人数的数据
        </div>
        <i class="iconfont-v3 saasicon_help_m m-l-4" />
      </vh-tooltip>
    </div>
    <ClickCardInfo
      :isEmbed="isEmbed"
      :embedRoomId="embedRoomId"
      :env="env"
      :cardId="cardId"
      :webinarId="$route.query.webinarId"
      :scene="scene"
      ref="cardInfo"
    />
  </div>
</template>
<script>
  import { ClickCardInfo } from '@vhcl/push-card';
  import { isEmbed, embedRoomId } from '@/utils/utils.js';
  import PageTitle from '@/components/PageTitle';
  export default {
    name: 'PushCards',
    components: {
      ClickCardInfo,
      PageTitle
    },
    props: {
      scene: {
        type: String,
        default: 'webinar' // webinar 活动层级；material 资料库
      }
    },
    data() {
      return {
        isEmbed: isEmbed(),
        embedRoomId: embedRoomId(),
        env: process.env.VUE_APP_ENV,
        cardId: this.$route.query.cardId || '',
        title: this.$route.query.title
      };
    },
    filters: {
      overHidden(value = '', len = 0) {
        if (value === null || value === undefined) return '';
        if (value.length > len) {
          return value.substring(0, len) + '...';
        }
        return value;
      }
    },
    mounted() {
      this.$refs.cardInfo.open({
        id: this.cardId
      });
    }
  };
</script>
<style lang="less" scoped>
  .cardTitle {
    display: inline-block;
  }
  .title-container {
    color: #1a1a1a;
    font-size: 22px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 27px;
    margin-top: 25px;
    span {
      display: inline-block;
    }
  }
</style>
